.container {
  padding: var(--unit_2, 8px) var(--unit_2, 8px) var(--unit_0, 2px);
  position: relative;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  border-radius: var(--unit_2, 8px);
  z-index: 1;
  overflow: hidden;
  min-height: 62px;

  &::before {
    position: absolute;
    inset: 0;
    background: linear-gradient(transparent, var(--superblack_60));
    content: '';
    z-index: 0;
  }
}

.link {
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  text-decoration: none;
  color: white;
}

.label {
  display: inline-block;
  padding: var(--unit_1, 4px) var(--unit_2, 8px);
  font-size: var(--fontSize_12px, 12px);
  line-height: var(--lineHeight_16px, 16px);
  background-color: #737a8e;
  border-radius: var(--unit_1, 4px);
  color: var(--text-inverted-default, white);
  height: fit-content;
}

.logo {
  flex: 0 0 auto;
  padding: var(--unit_0, 2px);
  width: 32px;
  height: 32px;
  border-radius: 4px;
  background: white;
  border: 1px solid var(--stroke-primary);
  overflow: hidden;
}

.logo-image {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.content {
  display: flex;
  flex-wrap: nowrap;
  column-gap: var(--unit_1, var(--unit_1, 4px));
}

.content-text {
  max-width: 228px;
  display: -webkit-box; /* stylelint-disable-line value-no-vendor-prefix */
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical; /* stylelint-disable-line property-no-vendor-prefix */
  flex: 1 1 auto;
}

.disclaimer {
  opacity: 0.5;
  margin: 0 calc(var(--unit_2, 8px) * -1);
}

.main {
  display: flex;
  flex-wrap: nowrap;
  column-gap: var(--unit_1, var(--unit_1, 4px));
  justify-content: space-between;
}
