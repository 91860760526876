.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: var(--unit_3);
  border: 1px solid #c9d1e5;
  width: 88px;
  height: 96px;
  padding: var(--unit_2);
  text-decoration: none;

  & > img {
    width: 40px;
    height: 40px;
  }
}
