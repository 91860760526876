.container {
  margin-top: 36px;
}

.title {
  margin: 0;
  margin-bottom: 20px;
  font-size: 18px;
  font-weight: bold;
  line-height: 20px;
  color: #2c2c2c;
}

.description {
  font-size: 16px;
  line-height: 20px;
  color: #2c2c2c;
}

.description p {
  margin: 0;
}
