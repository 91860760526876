.container {
  display: flex;
  flex-wrap: nowrap;
  overflow: auto hidden;
  gap: var(--unit_2);
  -webkit-overflow-scrolling: touch;

  & > * {
    flex: 0 0 auto;
  }

  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
}
