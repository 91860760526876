.container {
  margin: 0 auto;
  margin-bottom: 50px;
  padding: 0 var(--unit_4);
  max-width: 600px;
}

@media (min-width: 632px) {
  .container {
    padding: 0;
  }
}
