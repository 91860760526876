.container {
  margin: 0;
}

.margin + .margin {
  margin-top: 40px;
}

.title {
  margin: 0;
  margin-bottom: 20px;
  font-size: 22px;
  line-height: 28px;
  color: #121212;
}

.small-indent {
  margin-top: 16px;
}

.middle-indent {
  margin-top: 32px;
}

.large-indent {
  margin-top: 40px;
}
