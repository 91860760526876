.container + .container {
  border-top: 1px solid #e8e9ec;
}

.container > a {
  padding: 14px 0;
  display: flex;
  justify-content: space-between;
  font-size: 16px;
  line-height: 19px;
  text-decoration: none;
  color: var(--accent-main-primary);
}

.subtitle {
  font-size: 14px;
  line-height: 17px;
  color: #737a8e;
}
