.filters {
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 8px;
  justify-content: space-between;
}

.filter {
  padding: 0 16px;
  display: flex;
  align-items: center;
  height: 60px;
  gap: 8px;
  text-decoration: none;
  border: 1px solid #c9d1e5;
  border-radius: 8px;
}

.filter-icon {
  width: 16px;
  height: 16px;
}

@media (max-width: 360px) {
  .filter-icon {
    display: none;
  }
}
