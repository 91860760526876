.toggle-btn {
  position: relative;
  margin-top: 8px;
  padding-right: 20px;
  display: inline-block;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
  color: #2b87db;
}

.toggle-btn::after {
  position: absolute;
  top: 0;
  right: 4px;
  bottom: 0;
  margin: auto;
  width: 6px;
  height: 6px;
  content: '';
  border-left: 2px solid #2b87db;
  border-bottom: 2px solid #2b87db;
  border-radius: 1px;
  transform: rotate(-45deg);
}

.toggle-btn.toggle-btn--close::after {
  transform: translateY(calc(50% - 2px)) rotate(135deg);
}
