.container {
  border-bottom: 1px solid #e8e9ec;
}

.container > a {
  padding: 16px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 16px;
  line-height: 19px;
  text-decoration: none;
  color: #2c2c2c;
}
