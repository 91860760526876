.container {
  border-bottom: 1px solid var(--gray10_100);
}

.container:not(.container_expanded) .list {
  display: none;
}

.container:not(.container_expanded) .arrow-wrapper {
  transform: scaleY(-1);
}

.heading {
  font-size: var(--fontSize_16px);
  line-height: var(--lineHeight_24px);
}

.title {
  padding: 12px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.list {
  margin: 0;
  padding: 0 0 12px;
  display: grid;
  list-style: none;
  grid-template-columns: repeat(auto-fit, minmax(288px, 1fr));
  grid-gap: 4px 16px;
}

.link {
  font-size: var(--fontSize_14px);
  line-height: var(--lineHeight_20px);
  text-decoration: none;
  color: var(--gray60_100);
}
