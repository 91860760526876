.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.title {
  margin: 0 0 4px;
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
  color: #a1a7b3;
}

.text {
  margin: 0;
  font-size: 16px;
  line-height: 22px;
  color: #a1a7b3;
}
