.container {
  overflow: hidden;
  background: white;
  border: 1px solid #f1f1f1;
  border-radius: 6px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
}

.container + .container {
  margin-top: 24px;
}

.image {
  height: 120px;
  background-color: #ced1d7;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: cover;
  content-visibility: auto;
  contain-intrinsic-size: 120px;
}

.content {
  padding: 14px 24px 2px;
}

.title {
  margin: 0;
  font-size: 22px;
  font-weight: bold;
  line-height: 28px;
  color: var(--decorative-theme-dark);
}

.link {
  color: var(--decorative-theme-dark);
  text-decoration: none;
}

.list {
  margin: 0;
  margin-top: 10px;
  padding: 0;
  list-style: none;
}
