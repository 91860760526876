.container {
  position: fixed;
  z-index: 10;
  left: 0;
  bottom: 0;
  padding: 32px 16px 24px;
  width: 100%;
  background: white;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  box-shadow: 0 0 50px rgba(0, 0, 0, 0.2);
}

.close-icon {
  position: absolute;
  top: 16px;
  right: 16px;
}

.text {
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  color: #737a8e;
}

.text strong {
  color: var(--decorative-theme-dark);
  white-space: nowrap;
}

.buttons {
  margin-top: 24px;
  display: flex;
  justify-content: center;
}

.buttons button {
  max-width: 164px;
  flex-basis: 50%;
  font-size: 16px;
  font-weight: bold;
  line-height: 22px;
}

.buttons button:first-child,
.buttons button:last-child {
  padding: 0;
}

.buttons button:last-child {
  margin-left: 16px;
  white-space: nowrap;
  border: 1px solid var(--accent-main-primary);
  border-radius: 4px;
}

.buttons button:last-child span {
  color: var(--accent-main-primary) !important; /* stylelint-disable-line declaration-no-important */
}
