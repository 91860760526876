.title {
  display: block;
  margin: 8px 0 16px;
  font-size: 22px;
  font-weight: bold;
  line-height: 28px;
  text-align: left;
  color: var(--decorative-theme-dark);
  background: white;
  border-radius: 4px 4px 0 0;
  transform: translate3d(0, 0, 0);
}

.hidden {
  display: none;
}

.input-wrapper {
  margin-bottom: 16px;
}

.container {
  overflow: auto;
  height: 315px;
  max-height: 45vh;
  -webkit-overflow-scrolling: touch;
}

.wrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 100%;
}

.lists {
  margin: 0 0 auto;
  padding: 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  list-style: none;
}

.lists > li:not(.hidden) + li:not(.hidden) {
  border-top: 1px solid #e8e9ec;
  margin-top: 8px;
  padding-top: 8px;
}

@media (min-width: 640px) and (min-height: 812px) {
  .container {
    height: 45vh;
    max-height: 45vh;
  }
}
