@import '../../../../../node_modules/@cian/ui-kit/colors/variables.css';

.container {
  position: sticky;
  z-index: 10;
  top: 0;
  padding: 12px 16px;
  display: flex;
  height: 54px;
  flex-shrink: 0;
  background-color: white;
  border-bottom: 1px solid var(--gray10_100);
}

.left {
  margin-right: 8px;
  flex: 1 1 0;
}

.center {
  align-self: center;
  overflow: hidden;
}

.center span {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.right {
  margin-left: 8px;
  flex: 1 1 0;
  text-align: right;
}
