.container {
  margin: 0;
  margin-top: -16px;
  padding: 0;
  list-style: none;
}

.more {
  border-bottom: 1px solid #e8e9ec;
}

.more > button {
  padding: 16px 0;
  display: block;
  width: 100%;
  justify-content: space-between;
  font-size: 16px;
  line-height: 20px;
  text-align: left;
  text-decoration: none;
  color: #2c2c2c;
  background: none;
  border: none;
}
