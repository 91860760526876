.container {
  margin: 40px auto;
  display: flex;
  justify-content: center;
}

.promo-block {
  position: relative;
  flex-grow: 1;
  padding: var(--unit_3, 12px) var(--unit_4, 16px);
  display: block;
  box-sizing: border-box;
  max-width: 640px;
  height: 88px;
  text-decoration: none;
  background: url('https://cdn.cian.site/frontend/newbuilding-b2b-assets/images/pik/mainpage/promo-banner-mobile.png')
    no-repeat;
  background-size: cover;
  border-radius: var(--unit_1, 4px);
  content-visibility: auto;
  contain-intrinsic-size: 88px;
}

.disclaimer {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  padding: 0 var(--unit_4, 16px);
  line-height: var(--lineHeight_16px, 16px);
}

@media (min-width: 640px) {
  .promo-block {
    background: url('https://cdn.cian.site/frontend/newbuilding-b2b-assets/images/pik/mainpage/promo-banner-tablet.png')
      no-repeat;
  }
}
