.container {
  position: relative;
  display: flex;
  margin: 16px 0;
}

.icon {
  display: inline-flex;
  margin-right: 12px;
}

.geo-button {
  width: 100%;
  color: var(--decorative-theme-dark);
}

.placeholder {
  color: var(--gray40_100);
}

.button {
  display: flex;
  align-items: center;
  height: 40px;
  padding: 8px 10px;
  font-size: 16px;
  text-align: left;
  text-decoration: none;
  border-radius: 4px;
  border: 1px solid var(--black_20);
  background: var(--decorative-theme-white);
  outline: none;
}

.button:focus {
  box-shadow: 0 0 1px 1px var(--stroke-control-focused);
}

.text {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
